<template>
   <v-container>
      <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit" />
      <AConfirmation :isShow="isPendingSwapCompany" @cancel="cancelSubmit" @confirm="updateCurrentCompany" />
      <ASuccessFour :api="this.api" />
      <div class="d-flex justify-start">
         <v-btn @click="() => {
            this.$router.go(-1);
         }" class="mb-3" color="primary">
            <v-icon>
               mdi-arrow-left-bold
            </v-icon>
         </v-btn>
      </div>
      <v-skeleton-loader v-if="api.isLoading" ref="skeleton" type="table">

      </v-skeleton-loader>
      <v-card v-else>
         <v-toolbar class="white--text text-h5 mb-4" color="secondary">
            User Edit
         </v-toolbar>
         <div v-for="(item, index) in users" :key="index">
            <v-card class="ma-5 pa-2">
               <div class="d-flex justify-start mx-2 mt-4">
                  <p class="text-h5 font-weight-bold">
                     User {{ index + 1 }}
                  </p>
               </div>
               <v-row no-gutters>
                  <v-col>
                     <div class="px-2">
                        <div class="d-flex justify-start">
                           <div class="pr-3">
                              <v-text-field v-model="item.user_name" label="First Name" dense outlined>

                              </v-text-field>

                           </div>
                           <div>
                              <v-text-field v-model="item.user_lname" label="Last Name" dense outlined>

                              </v-text-field>
                           </div>
                        </div>
                        <div class="d-flex justify-start">
                           <v-text-field v-model="item.user_email" label="Email" dense outlined>

                           </v-text-field>
                        </div>
                        <div class="d-flex justify-start">
                           <v-text-field v-model="item.user_mobile" label="Mobile Phone" dense outlined>

                           </v-text-field>
                        </div>
                     </div>
                  </v-col>
                  <v-col class="px-3">
                     <div class="d-flex justify-start">
                        <v-autocomplete label="Main Category" :items="MainCategoryOptions"
                           v-model="item.user_subscription_type" dense outlined>

                        </v-autocomplete>
                     </div>
                     <div class="d-flex justify-start">
                        <v-text-field v-model="item.user_branch" label="Sub Category" dense outlined>

                        </v-text-field>

                     </div>
                     <div class="d-flex justify-start">
                        <v-autocomplete label="Role" :items="RoleOptions" v-model="item.user_role" dense outlined>

                        </v-autocomplete>
                     </div>
                  </v-col>
               </v-row>
                    <div class="d-flex px-2">
                        <v-autocomplete label="AAE Role" :items="AAERoleOptions" v-model="item.user_aae_role" dense outlined>
                        </v-autocomplete>
                    </div>
                    <div v-if="checkIfCanAccessBonusOffer()" class="d-flex px-2">
                        <v-autocomplete dense outlined v-model="item.user_account_type" :items="AccountUserOptions" label="User Account Type">
                        </v-autocomplete>
                    </div>
                     <div v-if="checkIfCanAccessBonusOffer()" class="d-flex px-2">
                        <v-text-field dense outlined v-model="item.user_bonus_offer" label="User Bonus Offer">
                        </v-text-field>
                     </div>
                    <div
                        class="d-flex px-2">
                        <v-autocomplete
                            dense
                            outlined 
                            :items="ExistingClientOptions"
                            v-model="item.existing_client"
                            label="Existing client ?">

                        </v-autocomplete>
                    </div>
                    <div
                        class="d-flex px-2">
                        <v-autocomplete
                            dense 
                            outlined 
                            :items="ActiveOptions"
                            v-model="item.user_active"
                            label="Active">

                        </v-autocomplete>
                     </div>
                     <v-row>
                        <v-col>
                           <ComponentCSField
                           :fieldValue="item.user_cs_pic"
                           @updateData="(data)=>{
                              item.user_cs_pic = data;
                           }"/>
                        </v-col>
                     </v-row>

                     <v-row no-gutters>
                        <v-col class="pb-0">
                           <div v-if="checkIfCanAccessOnboarding()" class="px-2">
                              <ComponentDateModal label="Welcome Guide Sent" :data="item.welcome_guide_sent" @updateDateData="(data) => {
                                 item.welcome_guide_sent = data;
                              }" />
                           </div>
                        </v-col>
                     </v-row>
                     <v-row>
                        <v-col class="pt-0">
                           <div v-if="checkIfCanAccessOnboarding()" class="px-2">
                              <ComponentDateModal label="Welcome WA Sent" :data="item.welcome_wa_sent" @updateDateData="(data) => {
                                 item.welcome_wa_sent = data;
                              }" />
                           </div>
                        </v-col>
                     </v-row>
                    <div class="d-flex px-2">
                        <v-text-field 
                            :disabled="!$store.getters.getRole.includes('developer')?true:false"
                            v-model="item.user_referral_points" label="Referral Points" dense outlined>

                  </v-text-field>
               </div>
               <div class="d-flex px-2">
                  <v-autocomplete :disabled="!$store.getters.getRole.includes('developer') ? true : false"
                     :items="userReferralOptions" v-model="item.referral_by_user_uuid" item-text="referral_uuid" dense
                     return-object outlined item-value="referral_uuid" label="User Refer By">
                     <template v-slot:item="{ item }">
                        {{ item.email }} ( {{ item.referral_uuid }} )
                     </template>
                     <template v-slot:selection="{ item }">
                        {{ item.email }}
                     </template>
                  </v-autocomplete>
               </div>
              
               <v-row>
                  <v-col class="mx-3">
                     <div class="d-flex justify-start">
                        <v-switch v-model="isVerified[index]">
                           <template v-slot:label>
                              <v-chip color="green" class="text-h7 white--text" v-if="isVerified[index] === true">
                                 <!-- {{ MappingSwitchIsVerified }} -->
                                 {{ isVerifiedCustomValue }}
                              </v-chip>
                              <span v-else>
                                 {{ isNotVerifiedCustomValue }}

                              </span>
                           </template>
                        </v-switch>
                     </div>

                     <div class="d-flex justify-start">
                        <v-switch v-model="isEnable[index]">
                           <template v-slot:label>
                              <v-chip color="green" class="text-h7 white--text" v-if="isEnable[index] === true">
                                 <!-- {{ MappingSwitchIsEnable }} -->
                                 {{ isEnableCustomValue }}
                              </v-chip>
                              <span v-else>
                                 <!-- {{ MappingSwitchIsEnable }} -->
                                 {{ isNotEnableCustomValue }}
                              </span>
                           </template>
                        </v-switch>
                     </div>

                  </v-col>
               </v-row>


            </v-card>
         </div>
         <div class="d-flex justify-end pa-5">

            <v-btn color="primary" plain @click="() => {
               this.$router.go(-1);
            }">
               Cancel
            </v-btn>
            <v-btn color="primary" @click="() => {
               this.isPending = true;
            }">
               Confirm
            </v-btn>
         </div>
      </v-card>
   </v-container>
</template>

<script>
import AConfirmation from '../../components/common/AConfirmation.vue';
import ASuccessFour from '../../components/common/ASuccessFour.vue';
import ComponentDateModal from '../../components/date/ComponentDateModal.vue';
import ComponentCSField from '../../components/company/field/ComponentCSField.vue';

export default{
    data: () => ({
        isPendingSwapCompany:false,
        isPending: false,
        companies: null,
        companiesAvailable:null,
        companiesAvailableSelected:null,
        tempCompaniesAvailableSelected:null,
        users: null,
        userReferralOptions:null,
        isVerified: false,
        isVerifiedCustomValue: 'Verified',
        isNotVerifiedCustomValue: 'Not Verified',
        isEnable: false,
        isEnableCustomValue: 'Enable',
        isNotEnableCustomValue: 'Disable',
        csPICOptions:null,
        AccountUserOptions:['primary',"secondary"],
        BonusOfferOptions: ['None'],
        MainCategoryOptions : ["SmartCFO Client","TaxPOD Client","Internal Staff"],
        RoleOptions: ["User", 'Admin', "Instructor","Manager","Consultant","Tax Expert"],
        AAERoleOptions:["client","consultant","tax expert","admin","manager"],
        ExistingClientOptions:['GST','YYC GST','Yes','998','HK PHUAH'],
        ActiveOptions:['Yes','No','Webinar'],
        api: {
            isLoading: false,
            isError: false,
            error: null,
            url: null,
            isSuccesful: false,
            success: null,
        }
    }),
    computed: {
        ComputeCompanyIsVerified(verified_at) {
            return (verified_at != null) ? true : false;
        },
        MappingSwitchIsVerified: {
            get(tempIsVerified) {
                return tempIsVerified == true ? this.isVerifiedCustomValue : this.isNotVerifiedCustomValue;
            },
            set(value) {
                this.isVerified = value === this.isVerifiedCustomValue;
            }
        },
        MappingSwitchIsEnable: {
            get() {
                return this.isEnable ? this.isEnableCustomValue : this.isNotEnableCustomValue;
            },
            set(value) {
                this.isEnable = value === this.isEnableCustomValue;
            },
        }
    },
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if (resp.class === "getCompanyFullInformation") {
                // let tempData = resp.data[0];
                // let tempCompany = {
                //     "company_name": tempData.company_name,
                //     "company_id": tempData.company_id,
                //     "company_address": tempData.address,
                //     "company_ssm": tempData.ssm_no,
                //     "company_industry": tempData.industry,
                //     "company_branch": tempData.branch,
                //     "company_subscription_type": tempData.subscription_type,
                //     "company_coupon": tempData.coupon,
                // };
                // this.companies = tempCompany;
                let tempUserVerifiedSwitch = [];
                let tempUserDisabledSwitch = [];
                let users = [];
                this.companiesAvailableSelected = resp.data[0].company_id;
                resp.data.forEach(data => {
                    let tempUser = {
                        "user_id":data.user_id,
                        "user_name": data.user_name,
                        "user_lname": data.user_lname,
                        "user_email": data.email,
                        "user_mobile": data.mobile,
                        "user_role": data.user_role,
                        "user_subscription_type": data.subscription_type,
                        "user_branch": data.branch,
                        "user_verified_at": data.email_verified_at,
                        "user_status": data.user_status,
                        "user_account_type":data.acc_category,
                        "user_bonus_offer": data.bonus_offer,
                        "user_aae_role":data.aae_role_name,
                        "user_referral_points":data.referral_points,
                        'referral_by_user_uuid':data.referral_by_user_uuid,
                        "welcome_guide_sent": (data.welcome_guide_sent!=null)?data.welcome_guide_sent.split(" ")[0]:null,
                        "welcome_wa_sent": (data.welcome_wa_sent!=null)?data.welcome_wa_sent.split(" ")[0]:null,
                        'existing_client':data.existing_client,
                        'user_active':data.user_active,
                        'user_cs_pic':{
                           'username':(data.cs_name!=null)?data.cs_name:null,
                           'id':(data.cs_id!=null)?data.cs_id:null,

                        },
                    };
                    tempUserDisabledSwitch.push(data.user_status != 0) ? true : false,
                        tempUserVerifiedSwitch.push(data.email_verified_at != null ? true : false);
                    users.push(tempUser);
                });
                this.isEnable = tempUserDisabledSwitch;
                this.isVerified = tempUserVerifiedSwitch;
                this.users = users;
            }
            if(resp.class==="getCompaniesClientActive"){
                let tempCompanies = resp.data.companies.filter(function(item){
                    if(item.verification!='Expired'){
                        return true;
                    }
                })
                this.companiesAvailable = tempCompanies;
            }
            if(resp.class=="updateClient"){
                this.api.isSuccesful = true;
                this.api.success = "Succesfully Update Client Detail";
            }
            if(resp.class=="getAvailableReferralUser") {
                this.userReferralOptions = resp.data;
            }
            this.api.isLoading = false;
            this.api.isError = false;
        };
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch() {
            let fetchCompaniesDetailApi = this.fetchCompaniesDetail();
            let fetchUserAvailableReferralApi = this.fetchUserAvailableReferral();
            this.$axios.all([
                this.$api.fetch(fetchCompaniesDetailApi),
                this.$api.fetch(fetchUserAvailableReferralApi),
            ]);

         if (this.$store.getters.getRole.includes('developer')) {
            let fetchCompaniesApi = this.fetchCompanies();
            this.$api.fetch(fetchCompaniesApi);
         }

        },
        fetchUserAvailableReferral(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method ="GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/referral/user/available/"+this.$router.history.current.params.id;
            return tempApi;
        },
        fetchCompaniesDetail() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/company/full/client/id/" + this.$router.history.current.params.id;
            return tempApi;
        },
        fetchCompanies(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/company/subscription/Active?role="+this.$store.getters.getRole+"&userId="+this.$store.getters.getUserId;
            return tempApi;
        },
        validateInput() {
            this.isPending = true;
        },
     
        submit(){
            this.isPending = false;
            let formData = new FormData;
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/user/client/company/"+this.$router.history.current.params.id;
            // formData.append("company_name",this.companies.company_name);
            // formData.append("company_address",this.companies.company_address);
            // formData.append("company_coupon",this.companies.company_coupon);
            // formData.append('company_ssm',this.companies.company_ssm);
            // formData.append('company_industry',this.companies.company_industry);
            // formData.append('company_branch',this.companies.company_branch);
            // formData.append('subscription_type',this.companies.company_subscription_type);
            // formData.append('company_coupon',this.companies.company_coupon);
            formData.append('causer_id',this.$store.getters.getUserId);
            this.users.forEach(user => {
                let tempUser =
                {
                    "user_id": user.user_id,
                    "user_lname": user.user_lname,
                    "user_fname": user.user_name,
                    "user_email": user.user_email,
                    "user_mobile": user.user_mobile,
                    "user_role":user.user_role,
                    "user_branch": user.user_branch,
                    "user_verified_at": this.isVerified[this.users.indexOf(user)] ,
                    "user_status":this.isEnable[this.users.indexOf(user)],
                    "subscription_type": user.user_subscription_type,
                    "user_aae_role":user.user_aae_role,
                    "account_type":user.user_account_type,
                    'user_bonus_offer': (user.user_bonus_offer != '') ? user.user_bonus_offer : null,
                    "welcome_guide_sent":user.welcome_guide_sent,
                    "welcome_wa_sent":user.welcome_wa_sent,
                    "existing_client":user.existing_client,
                    "active":user.user_active,
                    "cs_pic":user.user_cs_pic.id,
                    // "welcome_guide_sent": user.welcome_guide_sent ? new Date(user.welcome_guide_sent).toISOString() : null,
                    // "welcome_wa_sent": user.welcome_wa_sent ? new Date(user.welcome_wa_sent).toISOString() : null,
                    
                }
                formData.append('users[]',JSON.stringify(tempUser));
            });

         tempApi.params = formData;

         this.$api.fetch(tempApi);

      },
      checkIfCanAccessOnboarding() {
         if (this.$store.getters.getFeatures.onboarding.status == 1 || (this.$store.getters.getFeatures.onboarding.beta == 1 && this.$store.getters.getRole.includes('developer'))) {
            return true;
         }
         return false;
      },
      checkIfCanAccessBonusOffer() {
         if (this.$store.getters.getFeatures.accounttype.status == 1 || (this.$store.getters.getFeatures.accounttype.beta == 1 && this.$store.getters.getRole.includes('developer'))) {
            return true;
         }
         return false;
      },
      cancelSubmit() {
         this.isPending = false;
         this.isPendingSwapCompany = false;
      },
      updateCurrentCompany() {
         let tempApi = this.$_.clone(this.api);
         tempApi.method = "PUT";
         tempApi.url = process.env.VUE_APP_SERVER_API + ""
      }
   },
   components: { AConfirmation, ASuccessFour, ComponentDateModal,ComponentCSField }
}
</script>